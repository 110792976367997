import API_LINKS from "@/view/pages/painel/automacao/api.links";
import ApiService from "@/core/services/api.service";

export default class Utils {
  static setInterval(funcao, timeout) {
    return setInterval(funcao, timeout);
  }

  /**
   * Retorna o link do webhook da campanha.
   * @param campanha - Campanha a ser usada para pegar o token do webhook.
   * @returns {string} - Link do webhook da campanha.
   */
  static getLinkWebhookCampanha(campanha) {
    if (!campanha.webhook || !campanha.webhook.mdw_token) {
      return "";
    }
    return (
      process.env.VUE_APP_COMUNICANTE_API +
      API_LINKS.campanhaWebhook +
      "?token=" +
      (campanha ? campanha.webhook.mdw_token : "")
    );
  }

  /**
   * Retorna os recursos ativos de uma conta.
   * @param contaId - Id da conta a ser usada para pegar os recursos ativos. Caso seja null,
   * retorna os recursos ativos da conta do usuário logado.
   * @returns {Promise<unknown>}
   */
  static getRecursosByConta(contaId = null) {
    const path = contaId
      ? API_LINKS.recursosAtivos + "/" + contaId
      : API_LINKS.recursosAtivos;
    return ApiService.get(path, "", true);
  }

  /**
   * Formata a data de timestamp para o formato brasileiro.
   * @param timestamp - Data de timestamp a ser formatada.
   * @returns {string} - Data formatada.
   */
  static formateDateTimestampToBr(timestamp) {
    if (!timestamp) {
      return "";
    }
    const date = new Date(timestamp);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }

  /**
   * Retorna a mensagem de erro da response.
   * @param response - Response a ser usada para pegar a mensagem de erro.
   * @returns {*|string} - Mensagem de erro da response.
   */
  static formatResponseMessageToHTML(response) {
    if (
      response &&
      typeof response === "object" &&
      response.status &&
      response.data
    ) {
      const { status, data, message, error_code } = response.data;

      // Define a classe de estilo com base no status
      let alertClass = "alert-warning"; // Default para aviso
      if (status === "success") alertClass = "alert-success";
      if (status === "fail") alertClass = "alert-danger";

      // Monta o conteúdo da mensagem
      let content = `
      ${message ? `<p><strong>Mensagem:</strong> ${message}</p>` : ""}
      ${data ? `<p><strong>Detalhes:</strong> ${data}</p>` : ""}
      ${
        error_code
          ? `<p><strong>Código do erro:</strong> ${error_code}</p>`
          : ""
      }
    `;

      // Retorna o HTML com classes Bootstrap
      return `
      <div class="response-message alert ${alertClass}">
        ${content}
      </div>
    `;
    }

    // Retorno padrão para resposta inválida
    return `
    <div class="response-message alert alert-warning">
      <i>Formato de resposta inválido ou não suportado.</i>
    </div>
  `;
  }
}
